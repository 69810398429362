import { vertexShader, fragmentShader } from './tube';
import { colorRed1Vec,  colorRed2Vec,  colorRed1FracVec,  colorRed2FracVec, } from './tube';

AFRAME.registerComponent("chiller-out-warm-tube", {
  schema: {
      flowSpeed: { default: 0.2 }, // Speed of flow
      fraction: { default: 0.5 }, // fraction variable for the "heat" (color) of the cable
  },
  init: function () {
      const startWarmFromAbove = new THREE.Vector3(-14.5, 1, 3);
      const endWarmFromAbove = new THREE.Vector3(-15.2, -4, 3);
      const flowDirection = new THREE.Vector2(0, 1);
      const radius = 0.2;
      const warmFromAbovePoints = [
        endWarmFromAbove,
        new THREE.Vector3(startWarmFromAbove.x - 0.7, startWarmFromAbove.y - 0.3, startWarmFromAbove.z),
        new THREE.Vector3(startWarmFromAbove.x - 0.7, startWarmFromAbove.y - 0.3, startWarmFromAbove.z),
        new THREE.Vector3(startWarmFromAbove.x - 0.4, startWarmFromAbove.y, startWarmFromAbove.z),
        new THREE.Vector3(startWarmFromAbove.x - 0.4, startWarmFromAbove.y, startWarmFromAbove.z),
        //new THREE.Vector3(startWarmFromAbove.x - 0.7, startWarmFromAbove.y - 4, startWarmFromAbove.z),
        //new THREE.Vector3(startWarmFromAbove.x - 0.7, startWarmFromAbove.y, startWarmFromAbove.z),
        //new THREE.Vector3(startWarmFromAbove.x - 0.7, startWarmFromAbove.y + 1, startWarmFromAbove.z),
        startWarmFromAbove
      ];
      const warmFromAboveMaterial = new THREE.ShaderMaterial({
        uniforms: {
          time: { value: 0 },
          flowDirection: { value: flowDirection },
          flowSpeed: { value: this.data.flowSpeed },
          stripeWidth: { value: 0.1 }, // Adjust stripe width as needed
          opacity: { value: 0.8 },
          fraction: { value: this.data.fraction },
          color1: { value: colorRed1Vec },
          color2: { value: colorRed2Vec },
          colorFrac1: { value: colorRed1FracVec },
          colorFrac2: { value: colorRed2FracVec },
        },
        vertexShader: vertexShader,
        fragmentShader: fragmentShader,
      });
      const warmFromAboveCurve = new THREE.CatmullRomCurve3(warmFromAbovePoints);
      const warmFromAboveTubeGeometry = new THREE.TubeGeometry(warmFromAboveCurve, 10, radius, 8, false);
      const warmFromAboveTubeMesh = new THREE.Mesh(warmFromAboveTubeGeometry, warmFromAboveMaterial);
      this.el.setObject3D("warmFromAboveTubeMesh", warmFromAboveTubeMesh);

      this.el.sceneEl.addEventListener("tick", this.tick.bind(this));
  },
   tick: function (time, deltaTime) {
      const dt = (deltaTime / 1000) * this.data.flowSpeed;
      // Update time uniforms for shader animation
      this.el.getObject3D("warmFromAboveTubeMesh").material.uniforms.time.value += dt;
      this.el.getObject3D("warmFromAboveTubeMesh").material.uniforms.fraction.value = this.data.fraction;
    },
});
